<template>
  <div class="UserComplaints">
    <a-spin :spinning="spinning">
      <div class="header">
        <div class="header-content">
          状态:
          <a-radio-group button-style="solid" ref="status" @change="statusButton($event)">
            <a-radio-button value="">全部</a-radio-button>
            <a-radio-button value="0" style="margin-left: 10px">待回复</a-radio-button>
            <a-radio-button value="1">已回复</a-radio-button>
          </a-radio-group>
        </div>
      </div>

      <div class="main">
        <!----------------------table 列表区域---------------------->
        <a-table
            :columns="tableColumns"
            :data-source="tableData"
            rowKey="id"
            :pagination="false"
        >
          <template slot="status" slot-scope="text,item">
          <span v-if="item.isStatus === 1">
            <a-badge status="success"/> 已处理
          </span>
            <span v-else>
            <a-badge status="processing"/> 待处理
          </span>
          </template>
          <template slot="operation" slot-scope="text,item,index">
          <span v-if="item.isStatus === 1">
            <a @click="seeOpen(item)">查看</a>
          </span>
            <span v-else>
            <a @click="handleOpen(item)">处理</a>
            <a style="margin-left: 10px" @click="seeOpen(item)">查看</a>
          </span>
          </template>
        </a-table>

        <!----------------------查看抽屉区域---------------------->
        <a-drawer
            title="查看"
            :width="620"
            :visible="seeVisible"
            @close="seeOnClose"
        >
          <div class="drawerComplaint">
            <a-descriptions title="投诉信息" :column="2">
              <a-descriptions-item label="投诉人姓名">
                {{ seeForm.complainant }}
              </a-descriptions-item>
              <a-descriptions-item label="联系电话">
                {{ seeForm.tel }}
              </a-descriptions-item>
              <a-descriptions-item label="所在单位">
                {{ seeForm.unit }}
              </a-descriptions-item>
              <a-descriptions-item label="投诉时间">
                {{ seeForm.createdTime }}
              </a-descriptions-item>
              <a-descriptions-item label="投诉原因">
                {{ seeForm.reason }}
              </a-descriptions-item>
              <a-descriptions-item label="投诉渠道">
                {{ seeForm.channel }}
              </a-descriptions-item>
              <a-descriptions-item label="投诉内容">
                {{ seeForm.content }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="drawerImages">
            <img :src="item" v-for="item in seeForm.images" style="width: 100px;height: 60px" @click="imageClick(item)">
          </div>
          <div class="drawerHandle" v-if="seeForm.isStatus === 1">
            <a-divider/>
            <a-descriptions title="处理信息" :column="2">
              <a-descriptions-item label="处理状态" :span="2">
                {{ seeForm.isStatus === 1 ? '已处理' : '未处理' }}
              </a-descriptions-item>
              <a-descriptions-item label="处理人">
                {{ seeForm.userName }}
              </a-descriptions-item>
              <a-descriptions-item label="处理时间">
                {{ seeForm.handleTime}}
              </a-descriptions-item>
              <a-descriptions-item label="处理方案" :span="2">
                {{ seeForm.handleContent }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div
              :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
          >
            <a-button style="marginRight: 8px" @click="seeOnClose">
              取消
            </a-button>
            <a-button type="primary">
              确定
            </a-button>
          </div>
        </a-drawer>
      </div>

      <!----------------------点击处理弹出框---------------------->
      <a-modal
          title="处理"
          :visible="handleVisible"
          @ok="handleOk"
          @cancel="handleClose"
      >
        <a-form-model
            :model="handleForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="处理人">
            {{ handleForm.user_name }}
          </a-form-model-item>
          <a-form-model-item label="处理方案">
            <a-textarea placeholder="请输入" v-model="handleForm.text"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!----------------------图片点击放大---------------------->
      <a-modal
          :visible="imageVisible"
          :footer="null"
          @cancel="handleCancel"
          style="max-width: 800px"
      >
        <img :src="modalImage" style="max-width: 480px">
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {getUserComplaints, putUserComplaintsHandle} from "@/service/systemApi";

export default {
  name: 'userComplaints',
  data() {
    return {
      status: '',
      spinning: false,
      modalImage: '',
      imageVisible: false,
      handleVisible: false,
      seeVisible: false,
      handleForm: {},
      seeForm: {},
      labelCol: {span: 6},
      wrapperCol: {span: 17},
      tableColumns: [
        {
          title: '投诉原因',
          width: '20%',
          dataIndex: 'reason'
        },
        {
          title: '投诉人',
          width: '10%',
          dataIndex: 'complainant'
        },
        {
          title: '联系电话',
          width: '15%',
          dataIndex: 'tel'
        },
        {
          title: '单位',
          width: '15%',
          align: 'left',
          dataIndex: 'unit'
        },
        {
          title: '投诉渠道',
          width: '15%',
          dataIndex: 'channel'
        },
        {
          title: '状态',
          width: '10%',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '操作',
          width: '10%',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      tableData: [],
    }// dataReturn
  },
  created() {
    this.getUserComplaintsTableData()
  },
  methods: {
    seeOnClose() { // 关闭查看页面
      this.seeVisible = false
    },
    async seeOpen(row) { // 打开查看页面
      this.spinning = true
      const response = await getUserComplaints('', row.id)
      if (response.code === 0) {
        this.seeForm = response.data[0]
        this.seeVisible = true
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async handleOk() { // 处理对话框点击提交
      this.spinning = true
      let data = {
        "id": this.handleForm.handleId,
        "handleContent": this.handleForm.text
      }
      const response = await putUserComplaintsHandle(data)
      if (response.code === 0) {
        await this.getUserComplaintsTableData(this.status, '')
        this.$message.success("处理完成")
        this.handleVisible = false
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    handleClose() { // 处理对话框关闭
      this.handleVisible = false
    },
    handleOpen(row) { // 处理对话框打开
      let temp = window.localStorage.getItem("user_info") // 获取当前登录账户 id
      this.handleForm = JSON.parse(temp)
      this.handleForm.handleId = row.id
      this.handleVisible = true
    },
    async getUserComplaintsTableData(status, id) { // 获取 table 列表数据
      if (status === undefined) status = ''
      if (id === undefined) id = ''
      this.spinning = true
      const response = await getUserComplaints(status, id)
      if (response.code === 0) {
        this.tableData = response.data
        console.log(response.data)
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    handleCancel() { // 查看对话框关闭
      this.imageVisible = false
    },
    imageClick(e) { // 点击图片放大按钮
      this.modalImage = e
      this.imageVisible = true
    },
    async statusButton(e) { // 点击状态按钮显示 table 显示对应数据
      this.spinning = true
      this.status = e.target.value
      const response = await getUserComplaints(this.status, '')
      if (response.code === 0) {
        this.tableData = response.data
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    }
  }
}

</script>

<style lang="scss" scoped>
.UserComplaints {
  margin: -15px;

  .header {
    height: 75px;
    border-bottom: 15px solid rgba(240, 242, 245, 1);

    .header-content {
      margin: 25px 20px;
    }
  }

  .main {
    margin: 15px;
  }
}

.drawerComplaint {
  max-height: 50%;
}

.drawerImages {
  img {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
</style>